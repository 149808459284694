const config = {
  ENVIRONMENT_NAME: 'testing',
  forwoodId: {
    URL: 'https://id.testing.riotinto.forwoodsafety.com',
    APP_CLIENT_ID: '4nm1otjcnckdp9e4u9erf86a2n',
    APIGATEWAY: 'https://wzmc3sfhh4.execute-api.us-east-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://xgx3jodvae.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'testing',
    VERSION: 'resources-container-1.8.0',
    HOSTNAME: 'https://resources.testing.riotinto.forwoodsafety.com',
    TRAINING_MATERIALS_URL: 'https://iframe.testing.riotinto.forwoodsafety.com/training-materials',
    OFFICIAL_CRM_ICONS_URL: 'https://iframe.testing.riotinto.forwoodsafety.com/risk-icons',
    PRESENTATION_IFRAME_URL: 'https://iframe.testing.riotinto.forwoodsafety.com/node/11990',
    IFRAME_ORIGIN_URL: 'https://iframe.testing.riotinto.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
